import { useMemo } from 'react'
import WistiaReactPlayer from 'react-player/wistia'
import { VideoPlayer } from './Player'

export const WistiaPlayer = ({ playerId, autoplay, playbar = true, ...props }) => {
  const finalConfig = useMemo(
    () => ({
      wistia: {
        playerId,
        options: {
          playbar,
          autoPlay: autoplay,
        },
      },
    }),
    [autoplay, playbar, playerId],
  )

  return <VideoPlayer PlayerComponent={WistiaReactPlayer} config={finalConfig} {...props} />
}
