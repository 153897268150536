import React from 'react'
import { Field } from 'react-final-form'
import styled from 'styled-components'
import { useNeverBounceCheck } from 'api/neverbounce/useNeverBounceCheck'
import Link from 'next/link'
import { splitFullName } from 'utils/text/splitFullName'
import { required, requiredEmail, requiredUsOrUkPhone } from '@components/form/validation'
import Input from '@components/input/Input/Input'
import { View } from '@components/layout'
import { P } from '@components/typography'
import Form from '../Form/Form'
import PhoneInput from '../ReactPhoneInput/ReactPhoneInput'

const ClearBackgroundForm = styled(Form)`
  overflow-x: hidden;
  background: transparent;
  width: 100%;
  height: 100%;
  padding: 0;
`

const Background = styled(View)`
  border-radius: 5px;
  align-items: center;
  height: 100%;
  width: 100%;
  padding-top: ${(props) => props.theme.spacing.md}px;
`

const Heading = styled(P)`
  margin-top: ${(props) => props.theme.spacing.sm}px;
  text-align: center;
`

const TermsMessage = styled(P)`
  font-size: ${(props) => props.theme.fontSizes.sm}px;
  margin: ${(props) => props.theme.spacing.sm}px 0;
`

const StyledField = styled(Field)``

export const CaptureForm = ({
  initialValues,
  loading,
  onSubmit,
  setValues,
  closeDialog,
  heading,
  submitMsg = 'Get Access Now!',
  fieldsHidden,
  includePhone,
  ...props
}) => {
  const { isValidEmail, emailCheckLoading, validateEmail } = useNeverBounceCheck()

  return (
    <ClearBackgroundForm
      {...props}
      submitMsg={submitMsg}
      initialValues={initialValues}
      onSubmit={(vals) => {
        const { firstName, lastName } = splitFullName(vals.fullName)
        return onSubmit({
          firstName,
          lastName,
          email: vals.email,
          phone: vals.phone,
          acceptedTerms: true,
        }).catch((error) => {
          if (error.statusCode && error.statusCode === 400) {
            throw Error(error.message)
          } else {
            throw Error('Something went wrong. Please try again.')
          }
        })
      }}
    >
      <Background>
        {heading && <Heading>{heading}</Heading>}
        {!fieldsHidden && (
          <>
            <StyledField placeholder="Name" name="fullName" component={Input} validate={required} />
            <Field
              name="email"
              component={Input}
              placeholder="Email*"
              validate={requiredEmail}
              onChange={validateEmail}
              loading={emailCheckLoading}
              isVerified={isValidEmail}
            />
            {includePhone && (
              <Field name="phone" component={Input} componentClass={PhoneInput} validate={requiredUsOrUkPhone} />
            )}

            <TermsMessage>
              By clicking "{submitMsg}" you are agreeing to our{' '}
              <Link href="/terms">
                <a target="_blank">terms of use</a>
              </Link>{' '}
              and{' '}
              <Link href="/privacy-policy">
                <a target="_blank">privacy policy</a>
              </Link>
              .
            </TermsMessage>
          </>
        )}
      </Background>
    </ClearBackgroundForm>
  )
}
