import { useCallback, useState } from 'react'
import { debounce } from 'lodash'
import esAPI from 'services/esAPI/esAPI'

const nbSuccessResponse = 'valid'

export const useNeverBounceCheck = () => {
  const [emailCheckLoading, setEmailCheckLoading] = useState(false)
  const [isValidEmail, setIsValidEmail] = useState(false)

  const checkEmail = async (email) => {
    setEmailCheckLoading(true)
    setIsValidEmail(false)
    try {
      const response = await esAPI('post', '/nb-email', { email })
      setIsValidEmail(response.result === nbSuccessResponse)
      setEmailCheckLoading(false)
    } catch (error) {
      setEmailCheckLoading(false)
    } finally {
      setEmailCheckLoading(false)
    }
  }

  const validateEmail = useCallback(
    debounce((email) => {
      checkEmail(email)
    }, 1500),
    [],
  )

  return {
    validateEmail,
    emailCheckLoading,
    isValidEmail,
  }
}
