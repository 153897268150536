import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { FormSpy } from 'react-final-form'
import styled from 'styled-components'
import { FORM_ERROR } from 'final-form'
import PropTypes from 'prop-types'
import { PrimaryCTA, SecondaryCTA } from '../../input/Button'
import FormError from '../../input/FormError/FormError'
import { P } from '../../typography'

const FormWrapper = styled.div`
  background: ${(props) => props.bgColor || props.theme.colors.grayLighter};
  border-radius: 5px;
  padding: 20px;
  width: ${(props) => props.width};
`

const StyledForm = styled.form`
  display: flex;
  flex-flow: column nowrap;
`

const CenterHeading = styled(P)`
  font-size: 17px;
  text-align: center;
  margin-bottom: 1.2em;
  padding: 0 15%;
`

const ErrorMessage = styled(FormError)`
  margin-top: ${(props) => (props.error ? 20 : 0)}px;
`

const SubmitButton = styled(PrimaryCTA)`
  margin: ${(props) => (props.cancelButton ? 'initial' : 'auto')};
  margin-top: 10px;
  margin-bottom: 20px;
`

const CancelButton = styled(SecondaryCTA)`
  margin-top: 10px;
  margin-bottom: 20px;
  margin-right: 10px;
  font-weight: bold;
`

const SubmitContainer = styled.div`
  display: flex;
  justify-content: center;
`

const setValidation = ([name, errors], state) => {
  const field = state.fields[name]
  if (field) {
    field.touched = true
    field.error = errors[name]
  }
}

class Form extends React.Component {
  handleOnSubmit = (values) =>
    this.props
      .onSubmit(values)
      .then((result) => {
        if (this.props.onCompleted) {
          return this.props.onCompleted(result)
        }
      })
      .then(() => {
        // Return undefined at this point so the first does not receive errors
      })
      .catch((error) => ({ [FORM_ERROR]: error.message }))

  render() {
    const {
      title = '',
      submitMsg,
      submittingMsg,
      children,
      onCompleted,
      belowButton,
      cancelButton,
      onChangeValues,
      initialValues,
      setEditDetailsForm,
      ...props
    } = this.props
    return (
      <FormWrapper {...props}>
        {typeof title === 'string' ? title && <CenterHeading>{title}</CenterHeading> : title()}
        <FinalForm
          initialValues={initialValues}
          mutators={{ setValidation }}
          onSubmit={this.handleOnSubmit}
          render={({ submitting, handleSubmit, submitError, form, ...props }) => (
            <StyledForm
              onSubmit={async (values) => {
                await handleSubmit(values)
                this.props.resetOnSuccess && form.reset()
              }}
            >
              <FormSpy subscribe={{ values: true }} onChange={onChangeValues} />

              {children}
              <ErrorMessage submitting={submitting} error={submitError} />
              {submitMsg && (
                <SubmitContainer>
                  {cancelButton && (
                    <CancelButton onClick={() => setEditDetailsForm(false)}>Cancel</CancelButton>
                  )}
                  <SubmitButton type="submit" disabled={submitting} cancelButton={cancelButton}>
                    {submitting ? submittingMsg || `${submitMsg}` : `${submitMsg}`}
                  </SubmitButton>
                </SubmitContainer>
              )}
              {belowButton}
            </StyledForm>
          )}
        />
      </FormWrapper>
    )
  }
}

Form.defaultProps = {
  onChangeValues: () => {},
}

Form.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
  onSubmit: PropTypes.func.isRequired,
  submitMsg: PropTypes.string,
  onCompleted: PropTypes.func,
  onChangeValues: PropTypes.func,
}

export default Form
