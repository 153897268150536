import React, { useRef } from 'react'
import styled from 'styled-components'
import { noop } from 'lodash'
import PropTypes from 'prop-types'

const ResponsiveVideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%; /* Player ratio: 100 / (1280 / 720) */
`

const responsivePlayerStyle = {
  position: 'absolute',
  top: 0,
  left: 0,
}

const isSeeking = ({ previousTime, currentTime }) =>
  Math.abs(Math.round(previousTime - currentTime)) > 1

export const VideoPlayer = ({ PlayerComponent, onSeek, onTimeUpdate, style, ...props }) => {
  const prevTime = useRef(0)
  const currentTime = useRef(0)

  const handleProgress = (progress) => {
    onTimeUpdate(progress.playedSeconds)

    prevTime.current = currentTime.current
    currentTime.current = progress.playedSeconds

    const interval = { previousTime: prevTime.current, currentTime: currentTime.current }
    isSeeking(interval) && onSeek(interval)
  }

  return (
    <ResponsiveVideoWrapper>
      <PlayerComponent
        src={props.url}
        height="100%"
        width="100%"
        onProgress={handleProgress}
        style={responsivePlayerStyle}
        {...props}
      />
    </ResponsiveVideoWrapper>
  )
}

VideoPlayer.defaultProps = {
  onSeek: noop,
  onTimeUpdate: noop,
}

VideoPlayer.propTypes = {
  onSeek: PropTypes.func,
  onTimeUpdate: PropTypes.func,
}
