// https://www.npmjs.com/package/react-phone-input-2
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'

const phoneInputStyle = {
  border: 'grayLight',
  borderRadius: 10,
  width: '100%',
  height: 44,
}

const ReactPhoneInput = ({ defaultCountry = 'us', ...props }) => (
  <PhoneInput country={defaultCountry} inputStyle={phoneInputStyle} {...props} />
)

export default ReactPhoneInput
